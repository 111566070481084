import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/team.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const TeamPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit team'
      }}
      title="Team | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="team">
				<div className="content clearfix">

					<div className="section-block intro-title-2 header-image redFilter"></div>
					<div className="underHeader invert">
						<div className="item">
							<h1 data-aos = "fade-up" ><FormattedMessage id="proMainTitle" /></h1>
							<p data-aos = "fade-up" className="white"><FormattedHTMLMessage id="proMainSubtitle" /></p>
						</div>
					</div>

					<div className="section-block hero-5 right show-media-column-on-mobile">
						<div className="media-column width-6 center horizon" data-animate-in="preset:slideInRightShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/avv-alb-cirio2.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner right">
										<h4  data-aos = "fade-right" className="avv-name"><FormattedMessage id="proLaw1" /></h4>
										<p data-aos = "fade-right" data-aos-duration = "500"><FormattedHTMLMessage id="proText1.1" /></p>
										<p data-aos = "fade-right" data-aos-duration = "800"><FormattedHTMLMessage id="proText1.2" /></p>
										<p data-aos = "fade-right" data-aos-duration = "1000"><FormattedHTMLMessage id="proText1.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 hero-5-2 show-media-column-on-mobile">
						<div className="media-column width-6 horizon" data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/avv-luca-franc.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5 push-7">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner left">
										<h4 data-aos = "fade-left" className="avv-name"><FormattedMessage id="proLaw2" /></h4>
										<p data-aos = "fade-left" data-aos-duration = "500" ><FormattedHTMLMessage id="proText2.1" /></p>
										<p data-aos = "fade-left" data-aos-duration = "800"><FormattedHTMLMessage id="proText2.2" /></p>
										<p data-aos = "fade-left" data-aos-duration = "1000"><FormattedHTMLMessage id="proText2.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 right show-media-column-on-mobile">
						<div className="media-column width-6 center horizon" data-animate-in="preset:slideInRightShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/avv-alb-cirio.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner right">
										<h4 data-aos = "fade-right" className="avv-name"><FormattedMessage id="proLaw3" /></h4>
										<p data-aos = "fade-right" data-aos-duration = "300"><FormattedHTMLMessage id="proText3.1" /></p>
										<p data-aos = "fade-right" data-aos-duration = "600"><FormattedHTMLMessage id="proText3.2" /></p>
										<p data-aos = "fade-right" data-aos-duration = "900"><FormattedHTMLMessage id="proText3.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 hero-5-2 show-media-column-on-mobile">
						<div className="media-column width-6 horizon" data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/avv-raff-sart.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5 push-7">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner left">
										<h4 data-aos = "fade-left" className="avv-name"><FormattedMessage id="proLaw4" /></h4>
										<p data-aos = "fade-left" data-aos-duration = "500"><FormattedHTMLMessage id="proText4.1" /></p>
										<p data-aos = "fade-left" data-aos-duration = "800"><FormattedHTMLMessage id="proText4.2" /></p>
										<p data-aos = "fade-left" data-aos-duration = "1000"><FormattedHTMLMessage id="proText4.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 right show-media-column-on-mobile">
						<div className="media-column width-6 center horizon" data-animate-in="preset:slideInRightShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/avv-virg-maca.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner right">
										<h4 data-aos = "fade-right" className="avv-name"><FormattedMessage id="proLaw5" /></h4>
										<p data-aos = "fade-right" data-aos-duration = "500"><FormattedHTMLMessage id="proText5.1" /></p>
										<p data-aos = "fade-right" data-aos-duration = "800"><FormattedHTMLMessage id="proText5.2" /></p>
										<p data-aos = "fade-right" data-aos-duration = "1000"><FormattedHTMLMessage id="proText5.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 show-media-column-on-mobile">
						<div className="media-column width-6 center horizon" data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/elisa-sgubin-min.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5 push-7">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner left">
										<h4 data-aos = "fade-left" className="avv-name"><FormattedMessage id="proLaw7" /></h4>
										<p data-aos = "fade-left" data-aos-duration = "300"><FormattedHTMLMessage id="proText7.1" /></p>
										<p data-aos = "fade-left" data-aos-duration = "500"><FormattedHTMLMessage id="proText7.2" /></p>
										<p data-aos = "fade-left" data-aos-duration = "700"><FormattedHTMLMessage id="proText7.3" /></p>
										<p data-aos = "fade-left" data-aos-duration = "1000"><FormattedHTMLMessage id="proText7.4" /></p>
										<p data-aos = "fade-left" data-aos-duration = "1300"><FormattedHTMLMessage id="proText7.5" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 right hero-5-2 show-media-column-on-mobile">
						<div className="media-column width-6 horizon" data-animate-in="preset:slideInRightShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/daniele-maugeri-min.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner right">
										<h4 data-aos = "fade-right" className="avv-name"><FormattedMessage id="proLaw6" /></h4>
										<p data-aos = "fade-right" data-aos-duration = "300"><FormattedHTMLMessage id="proText6.1" /></p>
										<p data-aos = "fade-right" data-aos-duration = "500"><FormattedHTMLMessage id="proText6.2" /></p>
										<p data-aos = "fade-right" data-aos-duration = "500"><FormattedHTMLMessage id="proText6.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

          			<div className="section-block hero-5 hero-5-2 show-media-column-on-mobile">
						<div className="media-column width-6 horizon" data-animate-in="preset:slideInLeftShort;duration:1000ms;delay:200ms;" data-threshold="0.6">
							<img src="/images/team/blank.jpg" className="image" />
						</div>
						<div className="row">
							<div className="column width-5 push-7">
								<div className="hero-content split-hero-content">
									<div className="hero-content-inner left">
										<h4 data-aos = "fade-left" className="avv-name"><FormattedMessage id="proLaw10" /></h4>
										<p data-aos = "fade-left" data-aos-duration = "300"><FormattedHTMLMessage id="proText10.1" /></p>
										<p data-aos = "fade-left" data-aos-duration = "600"><FormattedHTMLMessage id="proText10.2" /></p>
										<p data-aos = "fade-left" data-aos-duration = "900"><FormattedHTMLMessage id="proText10.3" /></p>
										<p data-aos = "fade-left" data-aos-duration = "900"><FormattedHTMLMessage id="proText10.4" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					

				</div>
			</div>
		</Layout >
	</>
)

export default TeamPage
